<mat-dialog-content>
  <div fxLayout='row' fxLayoutAlign='space-between start'>
    <span>
      <h5 style='margin: 12px 0; font-size: 16px'>
        {{progressService.getStageLabel(data.stage, null) | translate}}
        <mat-icon inline style='color: black' [matTooltip]='progressService.getStageInfo(data.stage) | translate'>
          info
        </mat-icon>
      </h5>
    </span>

    <button mat-icon-button mat-dialog-close tabindex='-1' style='color: black'>
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <ng-container [ngSwitch]='data.stage'>
    <ng-container *ngSwitchCase='stages.officeInterviewInvitationEvent'>
      <div fxLayout='column' fxLayoutGap='8px' *ngIf='!showNextContactDate'>
        <div fxLayout='row' fxLayoutGap='8px' fxLayoutAlign='space-between start' class='col-border-box'>
          <mat-form-field>
            <mat-label>{{ 'CHOOSE_DATE' | translate }}</mat-label>
            <input matInput [matDatepicker]='picker' [(ngModel)]='date' />
            <mat-datepicker-toggle matSuffix [for]='picker'></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>

          <mat-form-field style='max-width: 80px;'>
            <mat-label>{{ 'TIME' | translate }}</mat-label>
            <input matInput [(ngModel)]='hour' />
            <mat-icon matSuffix inline='true'>schedule</mat-icon>
          </mat-form-field>
        </div>
      </div>

      <div fxLayout='column' fxLayoutGap='8px' class='second-step'>
        <ng-container *ngIf='! showNextContactDate'>
          <button mat-flat-button class='inprogress'
                  (click)='eventUpdate(null, eventTypes.Interview)'
                  [disabled]='loading || showNextContactDate'
          >
            <mat-icon fontSet='material-icons-outlined'></mat-icon>
            {{ (hasEventId() ? 'PROGRESS.RESCHEDULE_INTERVIEW_DATE' : 'PROGRESS.SCHEDULE_INTERVIEW_DATE') | translate }}
          </button>

          <button mat-flat-button class='success'
                  (click)='eventStatusUpdate(eventId, resolutions.Complete)'
                  *ngIf='hasEventId(stages.officeInterviewInvitationEvent)'
                  [disabled]='isBtnDisable() || showNextContactDate'
          >
            {{ 'PROGRESS.OFFICE_INTERVIEW_ARRIVED' | translate }}
          </button>

          <button mat-flat-button class='failed'
                  (click)='checkNoshow(eventId)'
                  *ngIf='hasEventId(stages.officeInterviewInvitationEvent)'
                  [disabled]='isBtnDisable() || showNextContactDate'>
            {{ 'PROGRESS.OFFICE_INTERVIEW_NOSHOW' | translate }}
          </button>
        </ng-container>

        <div *ngIf='showNextContactDate' fxLayoutGap='16px'>
          <h6>{{'PROGRESS.SCHEDULE_NEXT_CONTACT_DATE' | translate}}</h6>
          <mat-form-field>
            <mat-label>{{ 'CHOOSE_DATE' | translate }}</mat-label>
            <input matInput [matDatepicker]='picker2' [(ngModel)]='extraDate' />
            <mat-datepicker-toggle matSuffix [for]='picker2'></mat-datepicker-toggle>
            <mat-datepicker #picker2></mat-datepicker>
          </mat-form-field>

          <app-canellation-reason-select
            [offerPropositionId]='this.data.events.responseId'
            [getCommentsByID]='this.data.events.offerPropositionId'
            (selected)='onCancelationReasonsChange($event)'
            [country]='countryIsoId'>
          </app-canellation-reason-select>


          <button mat-flat-button color='primary'
                  matTooltip="{{ 'PROGRESS.SAVE_CONTACT_DATE_UPDATE_LIVAS' | translate }}"
                  (click)='updateLivasNextContactDate(eventId, eventTypes.Interview)'
          >
            <mat-icon> save</mat-icon>
            {{"SAVE" | translate }}

          </button>
        </div>

        <div *ngIf="getStepStatus('officeInterview') === 'COMPLETE'" class="porblematic-interview-block">
          <mat-checkbox color="primary" [(ngModel)]="isProblematicInterview" (change)="onProblematicInterviewChange()">
            {{ 'PROBLEMATIC_INTERVIEW' | translate }}
          </mat-checkbox>

          <div *ngIf="isProblematicInterview" fxLayout="column">
            <mat-form-field fxFlex="100" appearance="outline">
              <mat-label>{{ 'DESCRIPTION' | translate }}</mat-label>
              <textarea matInput [(ngModel)]="problematicInterviewDescription" [rows]="5"></textarea>
            </mat-form-field>

            <button mat-flat-button color='primary' [disabled]="problematicLoading" (click)="onProblematicInterviewChange()">
              {{ "SAVE" | translate }}
            </button>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase='stages.officeInterviewEvent'>
      <div fxLayout='column' fxLayoutGap='8px'>
        <button mat-flat-button class='success'
                (click)='setInterviewInterest(interested.Interested)'
                *ngIf='!selectedCancellationAnswer'>
          <mat-icon fontSet='material-icons-outlined'>thumb_up</mat-icon>
          {{ 'OFFER.POSITION_OFFERED_DOMINA' | translate }}
        </button>
        <button mat-flat-button class='failed'
                (click)='selectedCancellationAnswer = interested.NotInterested'
                *ngIf='!selectedCancellationAnswer'>
          <mat-icon fontSet='material-icons-outlined'>thumb_down</mat-icon>
          {{ 'OFFER.POSITION_OFFERED_NEDOMINA' | translate }}
        </button>
        <button mat-flat-button class='failed'
                (click)='selectedCancellationAnswer = interested.Undecided'
                *ngIf='!selectedCancellationAnswer'>
          <mat-icon fontSet='material-icons-outlined'>stop_circle</mat-icon>
          {{ 'OFFER.CANDIDATE_INTERESTED_REQUIREMENTS_NOT_MET' | translate }}
        </button>

        <ng-container *ngIf='selectedCancellationAnswer'>
          <ng-container *ngTemplateOutlet='livasStatusSelect'></ng-container>
          <mat-divider></mat-divider>
          <app-canellation-reason-select [offerPropositionId]='this.data.events.responseId'
                                         [getCommentsByID]='this.data.events.offerPropositionId'
                                         (selected)='onCancelationReasonsChange($event)'
                                         [country]='countryIsoId'>
          </app-canellation-reason-select>
          <button mat-flat-button class='failed' (click)='setInterviewInterest(selectedCancellationAnswer)'>
            {{ 'SAVE' | translate }}
          </button>

        </ng-container>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase='stages.presentToClientEvent'>
      <div fxLayout='row' fxLayoutGap='8px' fxLayoutAlign='space-between start' class='col-border-box'>
        <mat-form-field>
          <mat-label>{{ 'CHOOSE_DATE' | translate }}</mat-label>
          <input matInput [matDatepicker]='picker' [(ngModel)]='date' />
          <mat-datepicker-toggle matSuffix [for]='picker'></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>

        <mat-form-field style='max-width: 80px;'>
          <mat-label>{{ 'TIME' | translate }}</mat-label>
          <input matInput [(ngModel)]='hour' />
          <mat-icon matSuffix inline='true'>schedule</mat-icon>
        </mat-form-field>
      </div>

      <div fxLayout='column' fxLayoutGap='8px' *ngIf='!selectedNegativeResolutionStatus'>
        <button mat-flat-button class='inprogress'
                (click)='eventUpdate(null, eventTypes.ToBePresented)'>
          <mat-icon fontSet='material-icons-outlined'></mat-icon>
          {{   (data.events[data.stage]?.id ? 'PROGRESS.RESCHEDULE_NEXT_CONTACT_DATE' : 'PROGRESS.SCHEDULE_NEXT_CONTACT_DATE') | translate }}
        </button>

        <button mat-flat-button class='success' *ngIf='data.events[data.stage]?.id'
                (click)='eventStatusUpdate(eventId, resolutions.Complete)'
        >
          <mat-icon fontSet='material-icons-outlined'></mat-icon>
          {{ 'PROGRESS.CLIENT_PRESENTATION_SUCCESS' | translate }}
        </button>

        <button mat-flat-button class='failed'
                *ngIf='data.events[data.stage]?.id'
                (click)='selectedNegativeResolutionStatus =  resolutions.Failed'
        >
          <!--              (click)="eventStatusUpdate(eventId, resolutions.Failed)">-->
          <mat-icon fontSet='material-icons-outlined'>cancel</mat-icon>
          {{ 'PROGRESS.CANDIDATE_REFUSED' | translate }}
        </button>

        <button mat-flat-button class='failed'
                *ngIf='data.events[data.stage]?.id'
                (click)='selectedNegativeResolutionStatus =  resolutions.Rejected'
        >
          <!--              (click)="eventStatusUpdate(eventId, resolutions.Rejected)">-->
          <mat-icon fontSet='material-icons-outlined'>person_off</mat-icon>
          {{ 'PROGRESS.CLIENT_REJECTED' | translate }}
        </button>
      </div>

      <ng-container *ngIf='selectedNegativeResolutionStatus'>
        <app-canellation-reason-select [offerPropositionId]='this.data.events.responseId'
                                       [getCommentsByID]='this.data.events.offerPropositionId'
                                       (selected)='onCancelationReasonsChange($event)'
                                       [country]='countryIsoId'>
        </app-canellation-reason-select>
        <button mat-flat-button class='failed'
                (click)='setEventNegativeOutcome(eventId, selectedNegativeResolutionStatus, selectedCancellationReasons)'>
          {{ 'SAVE' | translate }}
        </button>
      </ng-container>

    </ng-container>

    <ng-container *ngSwitchCase='stages.clientInterviewEvent'>
      <div fxLayout='row' fxLayoutGap='8px' fxLayoutAlign='space-between start' class='col-border-box'>
        <mat-form-field>
          <mat-label>{{ 'CHOOSE_DATE' | translate }}</mat-label>
          <input matInput [matDatepicker]='picker' [(ngModel)]='date' />
          <mat-datepicker-toggle matSuffix [for]='picker'></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>

        <mat-form-field style='max-width: 80px;'>
          <mat-label>{{ 'TIME' | translate }}</mat-label>
          <input matInput [(ngModel)]='hour' />
          <mat-icon matSuffix inline='true'>schedule</mat-icon>
        </mat-form-field>
      </div>
      <div fxLayout='column' fxLayoutGap='8px' *ngIf='!selectedNegativeResolutionStatus'>
        <button mat-flat-button class='inprogress'
                (click)='eventUpdate(null, eventTypes.ClientInterview)'>
          <mat-icon fontSet='material-icons-outlined'></mat-icon>
          {{ 'PROGRESS.PLAN_CLIENT_INTERVIEW_DATE' | translate }}
        </button>

        <button mat-flat-button class='success' *ngIf='data.events[data.stage]?.id'
                (click)='eventStatusUpdate(eventId, resolutions.Complete)'>
          <mat-icon fontSet='material-icons-outlined'></mat-icon>
          {{ 'PROGRESS.CLIENT_INTERVIEW_SUCCESS' | translate }}
        </button>
        <button mat-flat-button class='failed' *ngIf='data.events[data.stage]?.id'
                (click)='selectedNegativeResolutionStatus = resolutions.Failed'>
          <mat-icon fontSet='material-icons-outlined'>cancel</mat-icon>
          {{ 'PROGRESS.CANDIDATE_REFUSED' | translate }}
        </button>

        <button mat-flat-button class='failed' *ngIf='data.events[data.stage]?.id'
                (click)='selectedNegativeResolutionStatus=resolutions.Rejected'>
          <mat-icon fontSet='material-icons-outlined'>person_off</mat-icon>
          {{ 'PROGRESS.CLIENT_REJECTED_CANDIDATE' | translate }}
        </button>

      </div>

      <ng-container *ngIf='selectedNegativeResolutionStatus'>
        <app-canellation-reason-select
          [offerPropositionId]='this.data.events.responseId'
          [getCommentsByID]='this.data.events.offerPropositionId'
          (selected)='onCancelationReasonsChange($event)'
          [country]='countryIsoId'>
        </app-canellation-reason-select>
        <button mat-flat-button class='failed'
                (click)='setEventNegativeOutcome(eventId, selectedNegativeResolutionStatus, selectedCancellationReasons)'>
          {{ 'SAVE' | translate }}
        </button>
      </ng-container>
    </ng-container>

    <ng-container *ngSwitchCase='stages.trialDay'>
      <div fxLayout='row' fxLayoutGap='8px' fxLayoutAlign='space-between start' class='col-border-box'>
        <mat-form-field>
          <mat-label>{{ 'CHOOSE_DATE' | translate }}</mat-label>
          <input matInput [matDatepicker]='picker' [(ngModel)]='date'/>
          <mat-datepicker-toggle matSuffix [for]='picker'></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>

        <mat-form-field style='max-width: 80px;'>
          <mat-label>{{ 'TIME' | translate }}</mat-label>
          <input matInput [(ngModel)]='hour'/>
          <mat-icon matSuffix inline='true'>schedule</mat-icon>
        </mat-form-field>
      </div>

      <div fxLayout='column' fxLayoutGap='8px' *ngIf='!selectedNegativeResolutionStatus'>
        <button mat-flat-button class='inprogress' (click)='eventUpdate(null, eventTypes.TrialDay)'>
          {{ 'PROGRESS.PLAN_TRIAL_DAY' | translate }}
        </button>

        <button
          *ngIf='data.events[data.stage]?.id'
          mat-flat-button
          class='success'
          (click)='eventStatusUpdate(eventId, resolutions.Complete)'
        >
          <mat-icon fontSet='material-icons-outlined'>thumb_up</mat-icon>
          {{ 'PROGRESS.SUCCESS' | translate }}
        </button>

        <button
          *ngIf='data.events[data.stage]?.id'
          mat-flat-button
          class='failed'
          (click)='eventStatusUpdate(eventId, resolutions.Failed)'
        >
          <mat-icon fontSet='material-icons-outlined'>thumb_down</mat-icon>
          {{ 'PROGRESS.FAILED' | translate }}
        </button>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase='stages.healthcheckEvent'>
      <div fxLayout='row' fxLayoutGap='8px' fxLayoutAlign='space-between start' class='col-border-box' *ngIf='! selectedNegativeResolutionStatus'>
        <mat-form-field>
          <mat-label>{{ 'CHOOSE_DATE' | translate }}</mat-label>
          <input matInput [matDatepicker]='picker' [(ngModel)]='date' />
          <mat-datepicker-toggle matSuffix [for]='picker'></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>

        <mat-form-field style='max-width: 80px;'>
          <mat-label>{{ 'TIME' | translate }}</mat-label>
          <input matInput [(ngModel)]='hour' />
          <mat-icon matSuffix inline='true'>schedule</mat-icon>
        </mat-form-field>
      </div>

      <div fxLayout='column' fxLayoutGap='8px' *ngIf='!selectedNegativeResolutionStatus'>
        <button mat-flat-button class='inprogress' (click)='eventUpdate(null, eventTypes.HealthCheckPending)'>
          <mat-icon fontSet='material-icons-outlined'></mat-icon>
          {{   (data.events[data.stage]?.id ? 'PROGRESS.RESCHEDULE_NEXT_CONTACT_DATE' : 'PROGRESS.SCHEDULE_NEXT_CONTACT_DATE') | translate }}
        </button>

        <button mat-flat-button class='success' *ngIf='data.events[data.stage]?.id'
                (click)='eventStatusUpdate(eventId, resolutions.Complete)'
        >
          <mat-icon fontSet='material-icons-outlined'></mat-icon>
          {{ 'PROGRESS.COMPLETED_HEALTH_CHECK' | translate }}
        </button>

        <button mat-flat-button class='failed' *ngIf='data.events[data.stage]?.id'
                (click)='selectedNegativeResolutionStatus =  resolutions.Failed'>
          <mat-icon fontSet='material-icons-outlined'>cancel</mat-icon>
          {{ 'PROGRESS.CANDIDATE_REFUSED' | translate }}
        </button>

        <button mat-flat-button class='failed'
                *ngIf='data.events[data.stage]?.id'
                (click)='selectedNegativeResolutionStatus =  resolutions.Rejected'
        >
          <mat-icon fontSet='material-icons-outlined'>person_off</mat-icon>
          {{ 'PROGRESS.CLIENT_REJECTED' | translate }}
        </button>
      </div>

      <ng-container *ngIf='selectedNegativeResolutionStatus'>
        <ng-container *ngTemplateOutlet='livasStatusSelect'></ng-container>
        <app-canellation-reason-select [offerPropositionId]='this.data.events.responseId'
                                       [getCommentsByID]='this.data.events.offerPropositionId'
                                       (selected)='onCancelationReasonsChange($event)'
                                       [country]='countryIsoId'>
        </app-canellation-reason-select>
        <button mat-flat-button class='failed'
                (click)='setEventNegativeOutcome(eventId, selectedNegativeResolutionStatus, selectedCancellationReasons)'>
          {{ 'SAVE' | translate }}
        </button>
      </ng-container>

    </ng-container>

    <ng-container *ngSwitchCase='stages.employmentEvent'>
      <div fxLayout='row' fxLayoutGap='8px' fxLayoutAlign='space-between start' class='col-border-box'>
        <mat-form-field>
          <mat-label>{{ 'CHOOSE_DATE' | translate }}</mat-label>
          <input matInput [matDatepicker]='picker' [(ngModel)]='date' />
          <mat-datepicker-toggle matSuffix [for]='picker'></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>

        <mat-form-field style='max-width: 80px;'>
          <mat-label>{{ 'TIME' | translate }}</mat-label>
          <input matInput [(ngModel)]='hour' />
          <mat-icon matSuffix inline='true'>schedule</mat-icon>
        </mat-form-field>
      </div>

      <div fxLayout='column' fxLayoutGap='8px' *ngIf='! selectedNegativeResolutionStatus'>
        <mat-slide-toggle *ngIf='!data.events[data.stage]?.id' [(ngModel)]='doResolvePreviousEvents'
                          stand>{{'CHECK_PREVIOUS_EVENTS_SUCCESS' | translate}}</mat-slide-toggle>
        <button mat-flat-button class='inprogress'
                (click)='eventUpdate(null, eventTypes.Employment, true, true)'>
          <mat-icon fontSet='material-icons-outlined'></mat-icon>
          {{ (data.events[data.stage]?.id ? 'PROGRESS.CHANGE_EMPLOYMENT_DATE' : 'PROGRESS.PLAN_EMPLOYMENT_DATE') | translate }}
        </button>

        <div fxLayout='row' fxLayoutGap='8px'>
          <button mat-flat-button class='success'
                  *ngIf='data.events[data.stage]?.id'
                  (click)='eventStatusUpdate(eventId, resolutions.Complete); employmentEventStatusUpdate(eventId, resolutions.Complete, data.events.orderType === OrderTypeEnum.selection ? livasResolutions.employedByClientSelections : livasResolutions.employedByClientStaffing)'>
            {{ 'PROGRESS.EMPLOYED_SUCCESSFLLY' | translate }}
          </button>
          <button mat-flat-button class='success'
                  *ngIf='data.events[data.stage]?.id && data.events.orderType !== OrderTypeEnum.selection'
                  (click)='eventStatusUpdate(eventId, resolutions.Complete); employmentEventStatusUpdate(eventId, resolutions.Complete, livasResolutions.employed)'>
            {{ 'PROGRESS.EMPLOYED_SUCCESSFULLY_BIURO' | translate }}
          </button>
        </div>

        <button mat-flat-button class='failed' *ngIf='data.events[data.stage]?.id'
                (click)='selectedNegativeResolutionStatus =  resolutions.Failed'>
          <mat-icon fontSet='material-icons-outlined'>cancel</mat-icon>
          {{ 'PROGRESS.CANDIDATE_REFUSED' | translate }}
        </button>

        <button mat-flat-button class='failed'
                *ngIf='data.events[data.stage]?.id'
                (click)='selectedNegativeResolutionStatus =  resolutions.Rejected'
        >
          <mat-icon fontSet='material-icons-outlined'>person_off</mat-icon>
          {{ 'PROGRESS.CLIENT_REJECTED' | translate }}
        </button>
      </div>

      <ng-container *ngIf='selectedNegativeResolutionStatus'>
        <ng-container *ngTemplateOutlet='livasStatusSelect'></ng-container>
        <app-canellation-reason-select [offerPropositionId]='this.data.events.responseId'
                                       [getCommentsByID]='this.data.events.offerPropositionId'
                                       (selected)='onCancelationReasonsChange($event)'
                                       [country]='countryIsoId'>
        </app-canellation-reason-select>
        <button mat-flat-button class='failed'
                (click)='setEventNegativeOutcome(eventId, selectedNegativeResolutionStatus, selectedCancellationReasons)'>
          {{ 'SAVE' | translate }}
        </button>
      </ng-container>
    </ng-container>
  </ng-container>


  <ng-template #livasStatusSelect>
    <mat-form-field class='livas-status-select' appearance='standard'>
      <mat-label>{{'PROGRESS.SELECT_LIVAS_STATUS' | translate }}</mat-label>
      <mat-select [(ngModel)]='selectedLivasStatus'>
        <mat-option *ngFor='let option of livasStatusOptions' [value]='option.id'>
          {{option.label | translate}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </ng-template>
</mat-dialog-content>
