<!--<app-specification-edit [specificationId]="'adsfioasoa'"></app-specification-edit>-->
<mat-slide-toggle
  [ngModelOptions]="{ standalone: true }"
  [matTooltip]="'ON_FOR_HINTS' | translate"
  [(ngModel)]="showHints"
>{{ 'SHOW_HINTS' | translate }}</mat-slide-toggle>

<mat-accordion [formGroup]="form" multi>
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
        <app-unfilled-field-indicator [unfilled]="showErrorForSection(0)"></app-unfilled-field-indicator>
        <span>{{ 'ORDER.WORK' | translate }}</span>
      </mat-panel-title>
    </mat-expansion-panel-header>

    <mat-form-field appearance="outline" *ngIf="orderType === orderTypes.selection || orderType === orderTypes.partialSelection">
      <mat-label>{{ 'ORDER.POSITION_TITLE' | translate }}</mat-label>
      <input formControlName='positionTitle' matInput>
    </mat-form-field>

    <app-text-input-with-hints
      *ngIf='$selectedCountry && (orderType === orderTypes.selection || orderType === orderTypes.partialSelection)'
      [selectedCountry]='$selectedCountry'
      [tagGroup]="'workFunctions'"
      [formControl]="form.get('workFunctions')"
      [showHints]='showHints'
    ></app-text-input-with-hints>

    <ng-container *ngIf='orderType === orderTypes.selection || orderType === orderTypes.partialSelection'>
      <app-text-input-with-hints
        *ngIf='$selectedCountry && (orderType === orderTypes.selection || orderType === orderTypes.partialSelection)'
        [selectedCountry]='$selectedCountry'
        [tagGroup]="'SPECIFICATION.ADDITIONAL_RECRUITEMENT_INFORMATION'"
        [formControl]="form.get('additionalRecruitementInformation')"
        [showHints]='false'
      ></app-text-input-with-hints>
      <!--      <mat-form-field appearance="outline"  >-->
      <!--        <mat-label>{{'SPECIFICATION.ADDITIONAL_RECRUITEMENT_INFORMATION' | translate }}</mat-label>-->
      <!--        <textarea formControlName="additionalRecruitementInformation" matInput rows="6"></textarea>-->
      <!--      </mat-form-field>-->
    </ng-container>

    <app-workplace-description
      *ngIf='orderType === orderTypes.staffing'
      [onTagsSelection]='onTagsSelection'
      [form]='form'
      [orderForm]='orderForm'
      [fxHide]='clientInterviewInProgress'
      [orderType]='orderType'
      [transportInfoTags]='tagsGroupDict[tagGroupMap.transportInfo]'
      [order]='order'
    ></app-workplace-description>
  </mat-expansion-panel>

  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
        <app-unfilled-field-indicator [unfilled]="showErrorForSection(1)"></app-unfilled-field-indicator>
        <span>{{ 'ORDER.REQUIREMENTS' | translate }}</span>
      </mat-panel-title>
    </mat-expansion-panel-header>

    <mat-checkbox
      color="primary"
      *ngIf="$selectedCountry && (orderType === orderTypes.selection || orderType === orderTypes.partialSelection)"
      [checked]="passRequirementsCtrl?.value"
      (change)="requirementsSelectionChange($event)"
    >
      {{ 'ORDER.INCLUDE_REQUIREMENTS_IN_ORDER' | translate }}
    </mat-checkbox>

    <app-text-input-with-hints
      *ngIf="$selectedCountry && (orderType === orderTypes.selection || orderType === orderTypes.partialSelection)"
      [tagGroup]="'requirements'"
      [formControl]="form.get('requirements')"
      [showHints]="showHints"
    ></app-text-input-with-hints>

    <div
      fxLayout="column"
      style="margin-top: 2.5rem;"
      *ngIf="orderType === orderTypes.selection || orderType === orderTypes.partialSelection"
      [formGroup]="form"
    >
      <mat-form-field appearance="outline" class="mat-selection-width">
        <mat-label>{{ 'competences1' | translate }}</mat-label>
        <mat-select multiple formControlName="competences">
          <mat-option style="background-color: {{competence?.node?.color}}"
            *ngFor="let competence of competences; let odd=odd"
            [value]="competence.node.id"
            >{{ competence.node.title }}</mat-option
          >
        </mat-select>
      </mat-form-field>
    </div>
    <!-- <app-text-input-with-hints [tagGroup]="'competences1'" [(ngModel)]="clientInterview.competences_1" [ngModelOptions]="{standalone: true}">
       </app-text-input-with-hints> -->

    <app-text-input-with-hints
      *ngIf="$selectedCountry && (orderType === orderTypes.selection || orderType === orderTypes.partialSelection)"
      [tagGroup]="'competences2'"
      [formControl]="form.get('competences2')"
      [showHints]="showHints"
    ></app-text-input-with-hints>

    <!-- <app-text-input-with-hints [tagGroup]="'companyRepresentation'" [(ngModel)]="clientInterview.companyRepresentation" [ngModelOptions]="{standalone: true}">
       </app-text-input-with-hints> -->

    <app-worker-description
      [form]="form"
      [order]="order"
      [onTagsSelection]="onTagsSelection"
      [fxHide]="clientInterviewInProgress"
      [clientAndContactForm]="clientAndContactForm"
      [transportInfoTags]="tagsGroupDict[tagGroupMap.provision]"
      [isRequirementsCheckboxVisible]="$selectedCountry && (orderType === orderTypes.selection || orderType === orderTypes.partialSelection)"
    ></app-worker-description>
  </mat-expansion-panel>

  <mat-expansion-panel
    *ngIf="orderType !== orderTypes.selection && orderType !== orderTypes.partialSelection"
    [fxHide]="clientInterviewInProgress"
  >
    <mat-expansion-panel-header>
      <mat-panel-title fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
        <app-unfilled-field-indicator [unfilled]="showErrorForSection(3)"></app-unfilled-field-indicator>
        <span>{{ 'ORDER.WORK_CLOTHING' | translate }}</span>
      </mat-panel-title>
    </mat-expansion-panel-header>

    <div fxFlex="100" fxLayout="column" fxLayoutGap="30px">
      <app-ppe-issuance-table
        [ppes]="personalProtectiveEquipment"
        (selected)="onSelectedChange($event)"
        [issuedAgency]="ppeIssuedByAgency"
        [issuedClient]="ppeIssuedByClient"
        fxFlex="100"
        fxFlex.lt-md="100"
      ></app-ppe-issuance-table>
      <mat-checkbox formControlName="clientWashesClothes">
        {{ 'ORDER.CUSTOMER_WASHES' | translate }}
      </mat-checkbox>
    </div>
  </mat-expansion-panel>

  <mat-expansion-panel
    *ngIf="orderType !== orderTypes.selection && orderType !== orderTypes.partialSelection"
    [fxHide]="clientInterviewInProgress"
  >
    <mat-expansion-panel-header>
      <mat-panel-title fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
        <app-unfilled-field-indicator [unfilled]="showErrorForSection(4)"></app-unfilled-field-indicator>
        <span>{{ 'ORDER.PECULIARITIES_OF_THE_WORKPLACE' | translate }}</span>
      </mat-panel-title>
    </mat-expansion-panel-header>

    <app-tag-assignment-table
      (selected)="onTagsSelection($event, tagGroupMap.workplaceBenefits)"
      [assigned]="tagAssignments"
      [tags]="tagsGroupDict[tagGroupMap.workplaceBenefits]"
    ></app-tag-assignment-table>

    <mat-checkbox color="primary" formControlName="isWorkplaceVideoAllowed" class="workplace-video-cb">
      {{ 'SPECIFICATION.IS_WORKPLACE_VIDEO_ALLOWED' | translate }}
    </mat-checkbox>
  </mat-expansion-panel>

  <mat-expansion-panel *ngIf="orderType === orderTypes.selection || orderType === orderTypes.partialSelection">
    <mat-expansion-panel-header>
      <mat-panel-title
        fxLayout="row"
        fxLayoutAlign="start center"
        fxLayoutGap="8px"
      >
        <app-unfilled-field-indicator
          [unfilled]="showErrorForSection(5)"
        ></app-unfilled-field-indicator>
        <span>{{ 'workConditions' | translate }}</span>
      </mat-panel-title>
    </mat-expansion-panel-header>

    <div style="margin-bottom: 25px;">
      <app-text-input-with-hints
        *ngIf="$selectedCountry"
        [selectedCountry]="$selectedCountry"
        [tagGroup]="'workConditions'"
        [formControl]="form.get('workConditions')"
        [showHints]="showHints"
      ></app-text-input-with-hints>
    </div>

    <app-workplace-description
      *ngIf="orderType === orderTypes.selection || orderType === orderTypes.partialSelection"
      [onTagsSelection]="onTagsSelection"
      [form]="form"
      [fxHide]="clientInterviewInProgress"
      [orderType]="orderType"
      [transportInfoTags]="tagsGroupDict[tagGroupMap.transportInfo]"
      [order]="order"
    ></app-workplace-description>

    <app-tag-assignment-table
      (selected)="onTagsSelection($event, tagGroupMap.workplaceBenefits)"
      [assigned]="tagAssignments"
      [tags]="tagsGroupDict[tagGroupMap.workplaceBenefits]"
    ></app-tag-assignment-table>

    <div fxLayout="column">
      <mat-checkbox
        color="primary"
        formControlName="isWorkplaceVideoAllowed"
        class="workplace-video-cb"
      >
        {{ 'SPECIFICATION.IS_WORKPLACE_VIDEO_ALLOWED' | translate }}
      </mat-checkbox>
      <mat-form-field appearance="outline">
        <mat-label>{{
          'SPECIFICATION.REQUIREMENTS_OTHERS' | translate
        }}</mat-label>
        <textarea
          *ngIf="orderType === orderTypes.selection || orderType === orderTypes.partialSelection"
          formControlName="requirementsOther"
          form
          matInput
          rows="6"
        ></textarea>
      </mat-form-field>
    </div>
  </mat-expansion-panel>

  <mat-expansion-panel *ngIf="orderType === orderTypes.selection || orderType === orderTypes.partialSelection">
    <mat-expansion-panel-header>
      <mat-panel-title fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
        <app-unfilled-field-indicator [unfilled]="showErrorForSection(6)"></app-unfilled-field-indicator>
        <span>{{ 'ORDER.SELECTION_PROCESS' | translate }}</span>
      </mat-panel-title>
    </mat-expansion-panel-header>

    <app-text-input-with-hints
      *ngIf="$selectedCountry"
      [selectedCountry]="$selectedCountry"
      [tagGroup]="'agencyProcessStandart'"
      [formControl]="form.get('agencyProcessStandart')"
      [showHints]="false"
      [hintAsPlaceholder]="true"
      [readonly]="true"
    ></app-text-input-with-hints>

    <app-text-input-with-hints
      *ngIf="$selectedCountry"
      [selectedCountry]="$selectedCountry"
      [tagGroup]="'selectionProcess'"
      [formControl]="form.get('selectionProcess')"
      [showHints]="showHints"
    ></app-text-input-with-hints>
  </mat-expansion-panel>
</mat-accordion>
