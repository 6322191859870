import { Component, Input, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import moment from 'moment/moment';
import { Moment } from 'moment';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';

import { Metric, MetricGroup } from './metric-table.model';
import { StandupDashboardServiceService } from '../standup-dashboard-service.service';
import { taskStatuses } from '../../../planner/planner-table/planner-table.component';
import { TaskServiceService } from '../../../planner/task-service.service';
import {
  ActionCategoryType,
  MeFieldsFragment, MetricGroupHighlightWhen,
  MetricValueCommentTypeConnection,
  TaskStatus,
  TaskType,
  UpdateOperationsTaskMutationVariables
} from '../../../../generated/graphql';
import { UserprofileService } from '../../../shared/userprofile.service';
import { PusherService } from '../../../pusher/pusher.service';
import { ReasonChipsData } from '../../../shared/reason-with-comment/reason-with-comment.component';
import { NewTaskDialogComponent } from '../../../planner/new-task-dialog/new-task-dialog.component';
import { FilterInterface } from '../../../shared/filter-bar/filter-bar.component';
import {
  TaskMultipleCreateDialogComponent
} from '../task-multiple-create-dialog/task-multiple-create-dialog.component';
import { ProblemDialogComponent } from './problem-dialog/problem-dialog.component';
import { RelayIdService } from '../../../shared/relay-id.service';

@Component({
  selector: 'app-metric-table',
  templateUrl: './metric-table.component.html',
  styleUrls: ['./metric-table.component.scss']
})
export class MetricTableComponent implements OnInit, OnDestroy {
  private pusherSubscription: Subscription;
  Object = Object;
  statuses = taskStatuses;
  moment = moment;
  metricGroups: MetricGroup[] = [];
  displayedColumns: string[] = [
    'metricGroup', 'selection', 'isImportant', 'isWorkis', 'pm', 'client', 'measureValue', 'goal', 'reasonComment', 'actionCategory', 'taskComment',
    'taskStatus', 'taskNumericPlan', 'taskDate', 'assignedTaskPm', 'actionCategoryL2', 'taskCommentL2', 'taskStatusL2',
    'taskDateL2', 'assignedTaskPmL2'
  ];
  tableData: any[] = [];
  metricCounts: { [key: string]: { total: number, important: number } } = { };
  user: MeFieldsFragment;
  selectedNextActions: { [key: string]: { l1: string, l2: string } } = { };
  nextTaskComments: { [key: string]: { l1: string, l2: string } } = { };
  categories;
  initDates;
  filterCache: FilterInterface;
  isDatePickerClosed: boolean;
  isLoading = true;
  selectedRowId: string;
  metricSelectionDict: { [key: string]: any } = { };
  onlyImportantShow: boolean;
  beData: any;
  metricGoalVsValueSum: {[key: string]: { goalSum: number, valueSum: number }} = {};
  metricLateToBeWithEmployeeSum: {[key: string]: { planSum: number }} = {};
  metricLateToBeWithEmployee: {[key: string]: {[key: string]: { plan: number, taskid: string[] }}} = {};
  tasks: any[];
  comments: any[];

  @Input() dashboard: string;
  @Input() dashboardId: string;
  @Input() cardId: any;

  constructor(
    private dashboardService: StandupDashboardServiceService,
    private taskService: TaskServiceService,
    private userprofileService: UserprofileService,
    private dialog: MatDialog,
    private pusherService: PusherService,
    private translate: TranslateService,
    private snackBar: MatSnackBar,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    public relayIdService: RelayIdService
  ) { }

  ngOnInit() {
    this.loadUserData();
    this.connectPusherTaskUpdate();

    const lastWorkingDay = this.getLastWorkingDay(moment());
    this.initDates = {
      dateRange: {
        dateFrom: lastWorkingDay,
        dateTo: moment()
      }
    };
    this.route.queryParams.subscribe(params => {
      const paramDateFrom = params['dateFrom'];
      const paramDateTo = params['dateTo'];
      this.selectedRowId = params['selectedMetric'];
      if (paramDateFrom && paramDateTo) {
        this.initDates = {
          dateRange: {
            dateFrom: moment(paramDateFrom),
            dateTo: moment(paramDateTo)
          }
        };
      }
    });
  }

  openProblemTaskDialog() {
    const config: MatDialogConfig<any> = {
      panelClass: 'rounded-dialog-16',
      width: '400px',
      autoFocus: false
    };

    this.dialog.open(ProblemDialogComponent, config).afterClosed().subscribe(value => {
      if (value) {
        this.onFilterChanges(this.filterCache, true);
        this.snackBar.open(
          this.translate.instant('CREATED_SUCCESSFULLY'), null, { duration: 5000, horizontalPosition: 'left' }
        );
      }
    });
  }

  loadMetrics(dateFrom?: string, dateTo?: string) {
    this.isLoading = true;
    this.dashboardService.getMetricTableValues(this.cardId, this.dashboardId, dateFrom, dateTo)
      .subscribe(
        data => {
          this.beData = data;
          this.prepareTableData(data);
        },
        () => this.isLoading = false
      );
  }

  loadUserData() {
    this.userprofileService.getMe().subscribe(profile => this.user = profile.data?.me);
  }

  connectPusherTaskUpdate() {
    this.pusherSubscription = this.pusherService.dashboardTaskUpdateEmitter.subscribe(event => {
      const metricObj = this.tableData.find(item => item.nextTask?.id === event.task_id);
      const metricObjL2 = this.tableData.find(item => item.nextTaskLevel2?.id === event.task_id);

      if (metricObj) {
        this.dashboardService.getUpdatedTask(event.task_id).subscribe(task => {
          metricObj.nextTask = task;
          this.nextTaskComments[metricObj.id].l1 = task.comment;
        });
      } else if (metricObjL2) {
        this.dashboardService.getUpdatedTask(event.task_id).subscribe(task => {
          metricObjL2.nextTaskLevel2 = task;
          this.nextTaskComments[metricObjL2.id].l2 = task.comment;
        });
      }
    });
  }

  selectRow(id: string) {
    if (this.selectedRowId === id) {
      this.selectedRowId = null;
    } else if (id !== undefined) {
      this.selectedRowId = id;
    }

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        selectedMetric: this.selectedRowId
      },
      queryParamsHandling: 'merge'
    });
  }

  onFilterChanges(filters: FilterInterface, force?: boolean) {
    const dateRange = filters.dateRange;
    const cachedRange = this.filterCache?.dateRange;

    this.isDatePickerClosed = !!(
      !this.filterCache || (dateRange.dateFrom.isSame(cachedRange.dateFrom) && (!cachedRange.dateTo && dateRange.dateTo))
    );

    this.filterCache = filters;

    if ((this.isDatePickerClosed || force) && dateRange && (dateRange.dateFrom && dateRange.dateTo)) {
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: {
          dateFrom: dateRange.dateFrom ? moment(dateRange.dateFrom).format('YYYY-MM-DD') : null,
          dateTo: dateRange.dateTo ? moment(dateRange.dateTo).format('YYYY-MM-DD') : null
        },
        queryParamsHandling: 'merge'
      });
      this.loadMetrics(
        dateRange.dateFrom ? moment(dateRange.dateFrom).format('YYYY-MM-DD') : null,
        dateRange.dateTo ? moment(dateRange.dateTo).format('YYYY-MM-DD') : null
      );
    }
  }

  metricGvVCalculator(metricName: string, metcirGoal: number, metricValue: number) {
    if (this.metricGoalVsValueSum[metricName]) {
      this.metricGoalVsValueSum[metricName].goalSum += metcirGoal;
      this.metricGoalVsValueSum[metricName].valueSum += metricValue;
    } else {
      this.metricGoalVsValueSum[metricName] = {
        goalSum: metcirGoal,
        valueSum: metricValue
      };
    }
  }

  metricPlanCalculator(metricName: string, metricPlan: number, task: any) {
    const metricEntry = this.metricLateToBeWithEmployee[metricName] || {};
    if (metricEntry[task.id]) {
      metricEntry[task.id].plan = metricPlan;
    } else {
      metricEntry[task.id] = {
        plan: metricPlan,
        taskid: [task.id]
      };
    }
    this.metricLateToBeWithEmployee[metricName] = metricEntry;
  }

  metricPlanTempleteOutput(metricName: string) {

    const metricData = this.metricLateToBeWithEmployee[metricName];

    const totalPlan = Object.values(metricData).reduce((sum, item) => sum + item.plan, 0);

    return totalPlan;
  }

  getTasksById(metricTasks, metricGroups, metricValuesIdsForComments) {
    if (metricTasks.length > 0) {
      this.dashboardService.getTasksByIdQuery(metricTasks).subscribe(
        data => {
          this.tasks = data.tasks.edges;
          if (this.tasks) {
            metricGroups.forEach(group => {
              const metricGroup = group.metricGroup;
              const metrics = metricGroup.metricvalueSet.edges.map(edge => edge.node);
              metrics.forEach(metric => {
                this.tasks.map( task => {
                  if (task.node.id === btoa(`TaskType:${metric.nextTaskId}`)) {
                    this.tableData.map( metricInTable => {
                      if (metricInTable.id === metric.id) {
                        metricInTable.nextTask = task.node;
                        metricInTable.nextTaskDate = metric.nextTask ? metric.nextTask.date : null;
                        metricInTable.nextTaskCompletedBy = metric.nextTask && metric.nextTask.completedBy ? metric.nextTask.completedBy.fullName : null;
                        if (!!this.getPlannedCustomFieldValue(metricInTable.nextTask)) {
                          this.metricPlanCalculator(metricGroup.title, this.getPlannedCustomFieldValue(metricInTable.nextTask), metricInTable.nextTask);
                          this.calculateMetricSums();
                        }
                        if (this.nextTaskComments[metric.id] && this.nextTaskComments[metric.id].l2) {
                          this.nextTaskComments[metric.id] = {
                            l1: task.node?.comment || '',
                            l2: this.nextTaskComments[metric.id].l2
                          };
                        } else {
                          this.nextTaskComments[metric.id] = {
                            l1: task.node?.comment || '',
                            l2: ''
                          };
                        }
                        if (this.selectedNextActions[metric.id] && this.selectedNextActions[metric.id].l2) {
                          this.selectedNextActions[metric.id] = {
                            l1: task.node?.nextActionCategory?.id || '',
                            l2: this.selectedNextActions[metric.id].l2
                          };
                        } else {
                          this.selectedNextActions[metric.id] = {
                            l1: task.node?.nextActionCategory?.id || '',
                            l2: ''
                          };
                        }
                      }
                    });
                  }
                  if (task.node.id === btoa(`TaskType:${metric.nextTaskLevel2Id}`)) {
                    this.tableData.map( metricInTable => {
                      if (metricInTable.id === metric.id) {
                        metricInTable.nextTaskLevel2 = task.node;
                        if (this.nextTaskComments[metric.id] && this.nextTaskComments[metric.id].l1) {
                          this.nextTaskComments[metric.id] = {
                            l1: this.nextTaskComments[metric.id].l1,
                            l2: task.node?.comment || ''
                          };
                        } else {
                          this.nextTaskComments[metric.id] = {
                            l1: '',
                            l2: task.node?.comment || ''
                          };
                        }
                        if (this.selectedNextActions[metric.id] && this.selectedNextActions[metric.id].l1) {
                          this.selectedNextActions[metric.id] = {
                            l1: this.selectedNextActions[metric.id].l1,
                            l2: task.node?.nextActionCategory?.id || ''
                          };
                        } else {
                          this.selectedNextActions[metric.id] = {
                            l1: '',
                            l2: task.node?.nextActionCategory?.id || ''
                          };
                        }
                      }
                    });
                  }
                });
              });
            });
          }
        },
      );
    }
    if (metricValuesIdsForComments.length > 0) {
      this.dashboardService.getCommentsByIdQuery(metricValuesIdsForComments).subscribe(
        data => {
          this.comments = data.metricValueComments.edges;
          if (this.comments) {
            metricGroups.forEach(group => {
              const metricGroup = group.metricGroup;
              const metrics = metricGroup.metricvalueSet.edges.map(edge => edge.node);
              metrics.forEach(metric => {
                this.comments.map( comment => {
                  if (metric.metricvaluecommentSet.edges[0] && metric.metricvaluecommentSet.edges[0].node) {
                    if (comment.node.id === metric.metricvaluecommentSet.edges[0].node.id) {
                      this.tableData.map( metricInTable => {
                        if (metricInTable.id === metric.id) {
                          metricInTable.metricvaluecommentSet.edges[0].node = comment.node;
                          metricInTable.reasonSet = this.groupReasonSet(metricInTable.metricvaluecommentSet);
                        }
                      });
                    }
                  }
                });
              });
            });
          }
        }
      );
    }
  }

  prepareTableData(metricGroups) {
    const metricTasks = [];
    const metricValuesIdsForComments = [];
    // console.log('metricGroups', metricGroups);
    this.tableData = [];
    this.metricGoalVsValueSum = {};
    this.metricLateToBeWithEmployeeSum = {};
    this.metricLateToBeWithEmployee = {};
    this.metricCounts = { };
    if (!metricGroups) {
      return;
    }

    metricGroups.forEach(group => {
      const metricGroup = group.metricGroup;
      metricGroup.nextActions?.edges.sort((x, y) => x.node.title > y.node.title ? 1 : -1);
      if (metricGroup && metricGroup.metricvalueSet && metricGroup.metricvalueSet.edges) {
        const metrics = metricGroup.metricvalueSet.edges.map(edge => edge.node);
        metrics.sort((a, b) => {
          if (a.manager?.fullName < b.manager?.fullName) {
            return -1;
          }
          if (a.manager?.fullName > b.manager?.fullName) {
            return 1;
          }

          if (a.client?.name < b.client?.name) {
            return -1;
          }
          if (a.client?.name > b.client?.name) {
            return 1;
          }

          if (a.id < b.id) {
            return -1;
          }
          if (a.id > b.id) {
            return 1;
          }

          return 0;
        });

        let dataSetIdx = 0;

        const importantMetrics = metrics.filter(metric => metric.isImportant);
        metrics.forEach((metric, index) => {
          if (metric.nextTaskId) {
            metricTasks.push(btoa(`TaskType:${metric.nextTaskId}`));
          }
          if (metric.nextTaskLevel2Id) {
            metricTasks.push(btoa(`TaskType:${metric.nextTaskLevel2Id}`));
          }
          if (metric.metricvaluecommentSet.edges[0]) {
            metricValuesIdsForComments.push(metric.id);
          }
          // console.log('metric', metric);
          if (!this.onlyImportantShow || this.onlyImportantShow && metric.isImportant) {
            this.tableData.push({
              ...metric,
              groupId: metricGroup.id,
              groupName: metricGroup.title,
              groupDescription: metricGroup.description,
              isGroupRow: dataSetIdx === 0,
              groupSpan: 1 + (this.onlyImportantShow ? importantMetrics.length : metrics.length),
              date: metric.date,
              measureValue: metric.value,
              goal: metric.goal,
              workis: metric.workis,
              managerName: metric.manager ? metric.manager.fullName : null,
              nextTaskDate: metric.nextTask ? metric.nextTask.date : null,
              nextTaskCompletedBy: metric.nextTask && metric.nextTask.completedBy ? metric.nextTask.completedBy.fullName : null,
              taskCategory: metricGroup.taskCategory,
              nextActions: metricGroup.nextActions?.edges.map(item => item.node),
              customFieldCategory: metricGroup.taskCategory,
              highlightWhen: metricGroup.highlightWhen,
              isTotalRow: false
            });
            if (metrics.length - 1 === index) {
              this.tableData.push({
                groupName: metricGroup.title,
                groupId: metricGroup.id,
                isTotalRow: true
              });
            }
            this.metricGvVCalculator(metricGroup.title, metric.goal, metric.value);
            this.selectedNextActions[metric.id] = {
              l1: '',
              l2: ''
            };
            this.nextTaskComments[metric.id] = {
              l1: '',
              l2: ''
            };
            if (!this.metricCounts[metricGroup.id]) {
              this.metricCounts[metricGroup.id] = { total: 0, important: 0 };
            }
            if (metric.isImportant) {
              this.metricCounts[metricGroup.id].important++;
            }
            this.metricCounts[metricGroup.id].total++;
            dataSetIdx++;
          }
        });
      }
    });
    this.getTasksById(metricTasks, metricGroups, metricValuesIdsForComments);
    this.isLoading = false;
  }

  groupReasonSet(set: MetricValueCommentTypeConnection): ReasonChipsData[] {
    const grouped: ReasonChipsData[] = [];
    set.edges.forEach(({ node }) => {
      const existReason = grouped.find(reason => reason.metricClientReason.id === node.metricClientReason.id);

      if (existReason) {
        existReason.multiplier = existReason.multiplier + 1;
        existReason.reasonGroup.push({ ...node });
      } else {
        grouped.push({ ...node, multiplier: 1, reasonGroup: [{ ...node }] });
      }
    });

    return grouped;
  }

  isMeasureValueExceeded(metric): boolean {
    if (metric.highlightWhen === MetricGroupHighlightWhen.GreaterThan) {
      return metric.measureValue > metric.goal && !metric.nextTask;
    } else if (metric.highlightWhen === MetricGroupHighlightWhen.LessThan) {
      return metric.measureValue < metric.goal && !metric.nextTask;
    }
  }

  isTaskStatusChangeDisabled(task: any): boolean {
    return task.status === 'DONE' && (task.schedules?.edges.length || task.createdBySchedule);
  }

  onDateChange(date: Moment, oldDate: string, task: TaskType) {
    const newDate = moment(date).format('YYYY-MM-DD');
    if (oldDate !== newDate) {
      this.updateTask({ taskId: task.id, date: newDate });
    }
  }

  onStatusChange(status: TaskStatus, task: TaskType) {
    this.updateTask({ taskId: task.id, status });
  }

  onNextActionChange(
    event: MatSelectChange,
    nextActions: ActionCategoryType[],
    metricId: string,
    taskCategory: any,
    level: string,
    clientId: string,
    candidateId: string,
    elementToUpdate: any
  ) {
    const proceedMetric = this.tableData.find(metric => metric.id === metricId);
    const createCompletedTask = nextActions.find(i => i.id === event.value).createCompletedTask;
    const action = nextActions.find(x => x.id === event.value).taskCategory;

    if (proceedMetric[level]) {
      this.updateTask({
        taskId: proceedMetric[level].id,
        nextActionCategory: event.value,
        category: action.id || taskCategory.id || null
      });
    } else {
      const dialog = this.dialog.open(NewTaskDialogComponent, {
        data: {
          nextActionCategory: event.value,
          createCompletedTask,
          category: action || taskCategory || null,
          user: this.user,
          clientId,
          candidateId,
          dashboardId: this.dashboardId,
          refetchQueries: 'GetOperationsTasks'
        },
        autoFocus: false
      });

      dialog.afterClosed().subscribe(value => {
        if (value) {
          if (level === 'nextTask') {
            this.dashboardService.setMetricValueNextTask(metricId, value.id).subscribe(
              resp => {
                this.snackBar.open(this.translate.instant('CREATED_SUCCESSFULLY'), null, { duration: 5000, horizontalPosition: 'left' });
                elementToUpdate.nextTask = resp.data.updateMetricValue.metricValue.nextTask;
                // this.metricPlanCalculator(elementToUpdate.groupName, this.getPlannedCustomFieldValue(elementToUpdate.nextTask), elementToUpdate.nextTask);
                // this.calculateMetricSums();
                this.nextTaskComments[elementToUpdate.id].l1 = elementToUpdate.nextTask.comment;
              },
              () => { }
            );
          } else if (level === 'nextTaskLevel2') {
            this.dashboardService.setMetricValueNextTaskL2(metricId, value.id).subscribe(
              resp => {
                this.snackBar.open(this.translate.instant('CREATED_SUCCESSFULLY'), null, { duration: 5000, horizontalPosition: 'left' });
                elementToUpdate.nextTaskLevel2 = resp.data.updateMetricValue.metricValue.nextTaskLevel2;
                this.nextTaskComments[elementToUpdate.id].l2 = elementToUpdate.nextTaskLevel2.comment;
              },
              () => { }
            );
          }
        } else {
          if (level === 'nextTask') {
            this.selectedNextActions[metricId].l1 = '';
          } else if (level === 'nextTaskLevel2') {
            this.selectedNextActions[metricId].l2 = '';
          }
        }
      });
    }
  }

  onSelectedProfilesChange($event: string[], task: TaskType) {
    this.updateTask({ taskId: task.id, completedBy: $event });
  }

  onCommentChange(metric: any, level: string, levelKey: string) {
    if (this.nextTaskComments[metric.id][level] !== metric[levelKey].comment) {
      this.updateTask({ taskId: metric[levelKey].id, comment: this.nextTaskComments[metric.id][level] });
    }
  }

  updateTask(updatesTask: UpdateOperationsTaskMutationVariables) {
    this.taskService.updateTaskPartial(updatesTask).subscribe(
      () => { }, () => { }
    );
  }

  openTaskDialog(element: any, taskLvl) {
    const task = element[taskLvl];
    const config: MatDialogConfig<any> = {
      data: { task: null },
      panelClass: 'rounded-dialog-16',
      width: '600px',
      height: '640px',
      autoFocus: false
    };

    if (task?.id) {
      this.taskService.getTask(task.id).subscribe(resp => {
        config.data.task = resp.data.task;
        this.location.replaceState(`/standups/${encodeURIComponent(task.id)}`);
        this.dialog.open(NewTaskDialogComponent, config).afterClosed().subscribe(value => {
          if (value?.delete) {
            // const plannedCustomField = task?.customFieldValues?.edges.find(({ node }) =>
            //   node.customField.fieldType.name.includes('plan')
            // );
            //
            // // if (plannedCustomField) {
            // //   for (const metricName in this.metricLateToBeWithEmployee) {
            // //     const tasks = this.metricLateToBeWithEmployee[metricName];
            // //     if (tasks[task.id]) {
            // //       delete this.metricLateToBeWithEmployee[metricName][task.id];
            // //       this.calculateMetricSums();
            // //     }
            // //   }
            // // }

            this.snackBar.open(
              this.translate.instant('DELETED_SUCCESSFULLY'), null, { duration: 5000, horizontalPosition: 'left' }
            );
            element[taskLvl] = null;
            this.selectedNextActions[element.id][taskLvl === 'nextTask' ? 'l1' : 'l2'] = '';
          } else if (value?.id) {
            const plannedCustomField = task?.customFieldValues?.edges.find(({ node }) =>
              node.customField.fieldType.name.includes('plan')
            );

            if (plannedCustomField) {
              for (const metricName in this.metricLateToBeWithEmployee) {
                const tasks = this.metricLateToBeWithEmployee[metricName];
                // if (tasks[task.id]) {
                //   this.metricLateToBeWithEmployee[metricName][task.id].plan = this.taskService.getCustomPlanValue();
                //   this.calculateMetricSums();
                // }
              }
            }

            this.snackBar.open(
              this.translate.instant('UPDATED_SUCCESSFULLY'), null, { duration: 5000, horizontalPosition: 'left' }
            );
          }

          this.location.replaceState('/standups');
        });
      });
    }
  }

  getLastWorkingDay(date) {
    let lastWorkingDay;

    switch (date.isoWeekday()) {
      case 1:
        lastWorkingDay = date.subtract(3, 'days');
        break;
      case 6:
        lastWorkingDay = date.subtract(1, 'days');
        break;
      case 7:
        lastWorkingDay = date.subtract(2, 'days');
        break;
      default:
        lastWorkingDay = date.subtract(1, 'days');
        break;
    }

    return lastWorkingDay;
  }

  openDescription(template: TemplateRef<any>) {
    this.dialog.open(template, {
      autoFocus: false,
      maxHeight: '80dvh'
    });
  }

  ngOnDestroy() {
    if (this.pusherSubscription) {
      this.pusherSubscription.unsubscribe();
    }
  }

  changeImportance(element) {
    this.dashboardService.updateMetricValueImportance(element.id, !element.isImportant).subscribe(
      re => {
        element.isImportant = re.data.updateMetricValue.metricValue.isImportant;
        if (element.isImportant) {
          this.metricCounts[element.groupId].important++;
        } else {
          this.metricCounts[element.groupId].important--;
        }

        this.beData.forEach(group => {
          group.metricGroup.metricvalueSet.edges.forEach(value => {
            if (value.node.id === element.id) {
              value.node.isImportant = element.isImportant;
            }
          });
        });
      }
    );
  }

  getPlannedCustomFieldValue(task: TaskType): undefined | number {
    const plannedCustomField = task?.customFieldValues?.edges.find(
      ({ node }) => node.customField.fieldType.name.includes('plan')
    );
    if (plannedCustomField) {
      return plannedCustomField.node.numericValue;
    } else {
      return undefined;
    }
  }

  calculateMetricSums(): {[key: string]: { planSum: number }} {
    const metricLateToBeWithEmployeeSum: {[key: string]: { planSum: number }} = {};

    for (const metricName in this.metricLateToBeWithEmployee) {
      const tasks = this.metricLateToBeWithEmployee[metricName];
      let planSum = 0;
      for (const taskId in tasks) {
        planSum += tasks[taskId].plan;
      }
      metricLateToBeWithEmployeeSum[metricName] = { planSum };
    }

    return this.metricLateToBeWithEmployeeSum = metricLateToBeWithEmployeeSum;
  }


  onSelectionChange(element) {
    if (this.metricSelectionDict[element.id]) {
      delete this.metricSelectionDict[element.id];
    } else {
      this.metricSelectionDict[element.id] = element;
    }
  }

  createMultipleTasks() {
    const selectedMetricsIds = Object.keys(this.metricSelectionDict);
    let availableActions = [];

    selectedMetricsIds.forEach(id => {
      this.metricSelectionDict[id].nextActions?.forEach(action => {
        if (action.createCompletedTask) {
          availableActions.push(action);
        }
      });
    });

    availableActions = availableActions.filter(action =>
      availableActions.filter(({ id }) => id === action.id).length === selectedMetricsIds.length
    );

    const uniqueActions = Array
      .from(new Set(availableActions.map(item => item.id)))
      .map(id => availableActions.find(item => item.id === id));

    const uniqueMetricValues = selectedMetricsIds
      .filter(id => this.metricSelectionDict[id].nextActions.some(action => uniqueActions.some(item => item.id === action.id)))
      .map(id => this.metricSelectionDict[id]);

    this.dialog.open(TaskMultipleCreateDialogComponent, {
      data: {
        uniqueActions,
        uniqueMetricValues,
        dashboardId: this.dashboardId,
        user: this.user
      }
    }).afterClosed().subscribe(resp => {
      if (resp) {
        this.snackBar.open(this.translate.instant('CREATED_SUCCESSFULLY'), null, { duration: 5000, horizontalPosition: 'left' });
        this.onFilterChanges(this.filterCache, true);
        this.metricSelectionDict = { };
      }
    });
  }

  onlyImportantVisibilityChange() {
    this.prepareTableData(this.beData);
  }

  formatInnerText(text: string): string {
    return text.replace(/\n/g, '<br>');
  }
}
