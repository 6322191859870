<!-- Dashboard select -->
<div class='dashboard-select' fxLayout fxLayoutAlign='space-between center'>
  <mat-form-field>
    <mat-select placeholder='Select Dashboard' [(value)]='selectedDashboard' (selectionChange)="onDashboardChange($event)">
      <mat-option *ngFor='let dashboard of dashboards | async' [value]='dashboard'>
        {{ dashboard.title }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <div class="members">
    <app-popup-select-complete
      [selected]="dashboardMembers"
      [addAvailable]="false"
      [removeAvailable]="false"
      [largeSpace]="true"
    ></app-popup-select-complete>
  </div>

  <button class="action-btn" mat-raised-button color="accent" [disabled]="!selectedDashboard?.id" (click)="openNewTaskDialog()">
    <mat-icon>add</mat-icon>
    <span>{{'NEW_TASK' | translate}}</span>
  </button>
</div>

<div class='dashboard-card' *ngFor='let card of selectedDashboard?.cards.edges'>
  <app-metric-table
    *ngIf="card.node.type === 'METRIC_TABLE'"
    [cardId]='card.node.id'
    [dashboardId]='selectedDashboard?.id'
  ></app-metric-table>

  <app-overdue-tasks-table
    *ngIf="card.node.type === 'OVERDUE_TASK_TABLE'"
    [dashboardId]='selectedDashboard?.id'
    [filterTaskCategoriesKeys]="card.node.filterTaskCategoriesKeys"
    [excludeTaskCategoriesKeys]="card.node.excludeTaskCategoriesKeys"
    [excludeStatuses]="card.node.excludeTaskStatuses"
    #taskOverdueTable
  ></app-overdue-tasks-table>

  <app-meeting-table
    *ngIf="card.node.type === 'CLIENT_MEETING_TASKS'"
    [cardTitle]='card.node.title'
    [cardDescription]='card.node.description'
    [dashboardMembers]='dashboardMembers'
    [dashboardId]='selectedDashboard?.id'
    [filterTaskCategoriesKeys]="card.node.filterTaskCategoriesKeys"
    [excludeTaskCategoriesKeys]="card.node.excludeTaskCategoriesKeys"
    [excludeStatuses]="card.node.excludeTaskStatuses"
    #meetingOverdueTable
  ></app-meeting-table>

  <app-dynamic-dashboard-tasks-table
    *ngIf="card.node.type === 'DYNAMIC_TASKS_TABLE'"
    [cardTitle]='card.node.title'
    [cardDescription]='card.node.description'
    [dashboardId]='selectedDashboard?.id'
    [filterTaskCategoriesKeys]="card.node.filterTaskCategoriesKeys"
    [excludeTaskCategoriesKeys]="card.node.excludeTaskCategoriesKeys"
    [excludeStatuses]="card.node.excludeTaskStatuses"
  ></app-dynamic-dashboard-tasks-table>
</div>
