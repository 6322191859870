import {AfterViewInit, Component, Input, OnInit, ViewChild,} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort, Sort} from '@angular/material/sort';
import {MatTable} from '@angular/material/table';
import {OffersTableDataSource, OffersTableItem, ResponseTypes,} from './offers-table-datasource';
import {GroupByPipe} from 'ngx-pipes';
import {OrderTrafficGeneration} from '../../shared/types/order-traffic-generation';
import {
  OfferPropositionCreateGQL,
  OfferPropositionUpdateGQL,
  OfferPropositionLongListed,
  EventEventType,
  EventResolutionStatus,
  InterviewWidgetOffersGQL,
  OfferEventFieldsFragment,
  OfferPropositionType,
  OfferPropositionInterestedOnCall,
  OfferPropositionInterested,
  OfferPospositionOffersGQL,
} from '../../../generated/graphql';
import moment from 'moment';
import 'moment/locale/lt';
import 'moment/locale/lv';
// import 'moment/locale/ee';
import 'moment/locale/et';

import { ProgressBarEvent } from '../../interview-widget/offers-table/progress-component/types';
import tippy from 'tippy.js';
import { CommentDialogComponent } from '../../interview-widget/comment-dialog/comment-dialog.component';

const CALL_OFFER_RESPONSES = {
  [OfferPropositionInterestedOnCall.Interested]: 'Susidomėjo skambučio metu',
  [OfferPropositionInterestedOnCall.NotInterested]: 'Siūlė, bet nesudomino skambučio metu',
  [OfferPropositionInterestedOnCall.Undecided]: 'Skambučio metu neturėjo nuomonės',
};

const WAS_NOT_OFFERED = 'Nepasiūlyta skambučio metu';

@Component({
  providers: [GroupByPipe],
  selector: 'app-offers-table',
  templateUrl: './offers-table.component.html',
  styleUrls: ['./offers-table.component.scss'],
})
export class OffersTableComponent implements AfterViewInit, OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatTable) table: MatTable<OffersTableItem>;
  dataSource: OffersTableDataSource;

  clientInterview: OfferEventFieldsFragment;

  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = ['name', 'offerOnCallStatus', 'offer', 'comment', 'candidateEvaluation'];

  offerPropositionInterested = OfferPropositionInterested;
  offerPropositionInterestedOnCall = OfferPropositionInterestedOnCall;
  offerPropositionLongListed = OfferPropositionLongListed;

  filterValue = '';
  originalDS: OffersTableItem[];

  @Input() locale: string;
  // @Input() sex: string;
  private $country: string;
  @Input() set country(value: string) {
    this.$country = value;
  };
  @Input() personId: number;
  @Input() set columns(value: string[]) {
    if (value) {
      this.displayedColumns = value;
    }
  }

  constructor(
    private dialog: MatDialog,
    private offersTableGQL: OfferPospositionOffersGQL,
    private offerPropositionCreateGQL: OfferPropositionCreateGQL,
    private offerPropositionUpdateGQL: OfferPropositionUpdateGQL,
    private groupBy: GroupByPipe,
    // private cancellationService: CancellationServiceService
  ) {}

  ngOnInit() {
    this.dataSource = new OffersTableDataSource();

    this.getRows();

  }
  compare(createdAt: string, createdAt2: string, isAsc: boolean) {
    return (createdAt < createdAt2 ? -1 : 1) * (isAsc ? 1 : -1);
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.table.dataSource = this.dataSource;

    this.sort.sortChange.subscribe(() => {
      this.dataSource.data.next(this.dataSource.data.value.sort((a, b) => {
        const isAsc = this.sort.direction === 'asc';

        switch (this.sort.active) {
          case 'createdAt':
            return this.compare(a.createdAt, b.createdAt, isAsc);
          default:
            return 0;
        }
      }));
    });

  }

  private getLongListedEvent(value): ProgressBarEvent | null {
    const statusMap = {
      [OfferPropositionLongListed.Included]: 'SUCCESS',
      [OfferPropositionLongListed.RejectedWithoutCall]: 'FAILED'
    };

    const status = value in statusMap ? statusMap[value] : null;

    return {status, icon: 'playlist_add_circle', index: 0};
  }

  private getOfferEvent(value: ResponseTypes, onCall: boolean, dateTime?: Date): ProgressBarEvent | null {
    const statusMap = {
      [ResponseTypes.agreed]: 'SUCCESS',
      [ResponseTypes.undecided]: 'PENDING',
      [ResponseTypes.notInterested]: 'FAILED',
    };
    const icon = onCall ? 'check_circle_outline' : 'thumb_up';
    return {status: value ? statusMap[value] : null , icon, index: onCall ? 1 : 3, startDatetime: dateTime};
  }

  private getProgressBarEventFromEvent(events, value: EventEventType, index: number): ProgressBarEvent | null {
    const eventNode = events.find(n => n.node.eventType === value)?.node;

    const icons = {
      [EventEventType.Interview]: 'meeting_room',
      [EventEventType.ToBePresented]: 'co_present',
      [EventEventType.ClientInterview]: 'event',
      [EventEventType.TrialDay]: 'forklift',
      [EventEventType.HealthCheckPending]: 'fact_check',
      [EventEventType.Employment]: 'receipt_long'
    };

    const statusMap = {
      [EventResolutionStatus.Complete]: 'SUCCESS',
      [EventResolutionStatus.Failed]: 'FAILED',
      [EventResolutionStatus.Cancelled]: 'FAILED',
      [EventResolutionStatus.Rejected]: 'REJECTED'
    };

    const status = eventNode ? (eventNode.resolutionStatus ? statusMap[eventNode.resolutionStatus] : 'PENDING') : null;

    return {status, icon: icons[value], index, id: eventNode?.id, startDatetime: eventNode?.startDatetime};
  }

  showCancellationReasonsTooltip(element, id) {
    const t: any = tippy(element, {
      content: () => {
        const template = document.getElementById(id);
        return template.innerHTML;
      },
      allowHTML: true,
      arrow: true,
      interactive: true,
      theme: 'material'
    });
    t?.show();
  }

  openCommentDialog($event, row) {
    const dialogRef = this.dialog.open(CommentDialogComponent, {
      panelClass: ['padding-16-dialog', 'width-650-dialog'],
      data: {
        propositionID: row.offerPropositionId
      },
    });

    dialogRef.afterClosed().subscribe(() => this.getRows());
  }

  getRows() {
    moment.locale(this.locale === 'et' ? 'ee' : this.locale);
    this.offersTableGQL
      .watch(
        {
          // sex: this.sex,
          orderBy: '-created_at',
          personId: this.personId,
          country: this.$country,
          trafficGeneration: [
            OrderTrafficGeneration.activeNoMarketing,
            OrderTrafficGeneration.activeWithMarketing,
          ].join(','),
        },
        { fetchPolicy: 'no-cache' }
      )
      .valueChanges.subscribe((resp) => {
        if (resp.loading) {
          return;
        }
        const offers = resp.data.offers.edges.map((it) => it.node);
        const orders: OffersTableItem[] = [];
        offers.forEach(
          (it) => {
            const originalOrderId = it.order.id;
            const offerPropositionId = it.id;
            const response = [{ ...it }];
            response[0].id = response[0].id
              ? atob(response[0].id).split(':')[1]
              : undefined;
            const node = it;
            const interview =
              (response[0].eventSet &&
                response[0].eventSet.edges.find(
                  (edge) =>
                    edge.node.eventType === EventEventType.ClientInterview
                )) ||
              undefined;

            const spec = node.order.specification;

            const events = response[0].candidateEvents?.edges || [];

            this.clientInterview = interview && interview.node;

            const interviewEvent = response[0]?.candidateEvents?.edges?.find((edge) => edge.node.eventType === EventEventType.Interview)?.node;
            const healthCheckPendingEvent = response[0].candidateEvents?.edges.find(
              (edge) => edge.node.eventType === EventEventType.HealthCheckPending)?.node;
            const presentingToClientEvent = null;


            const map = {
              companyName: spec && spec.company.name,
              companyStatus: spec && spec.company.status,
              color: spec && spec.company.color,
              interested: response[0].interested,
              interestedOnCall: response[0].interestedOnCall,
              comment: response[0].comment,
              id: it.id,
              title: spec && spec.positionTitle,
              responseId: response[0].id,
              signed: response[0].signed,
              candidate: response[0]?.candidate?.id,
              interview: this.clientInterview,
              candidateEvent: response[0]?.candidateEvents?.edges,
              cancellationReasonsAssignmentSet: response[0]?.cancellationreasonassignmentSet?.edges.map(crNode => crNode.node),
              createdAt: response[0]?.createdAt,
              longListed: response[0].longListed,
              offerpropositioncommentSet: it.offerpropositioncommentSet,
              longListEvent: this.getLongListedEvent(response[0].longListed),
              introductionCallEvent: this.getOfferEvent(response[0].interestedOnCall as any, true),
              officeInterviewInvitationEvent: this.getProgressBarEventFromEvent(events, EventEventType.Interview, 2),
              officeInterviewEvent: this.getOfferEvent(response[0].interested as any, false, node.firstInterestedAt),
              presentToClientEvent: this.getProgressBarEventFromEvent(events, EventEventType.ToBePresented, 4),
              clientInterviewEvent: this.getProgressBarEventFromEvent(events, EventEventType.ClientInterview, 5),
              trialDay: this.getProgressBarEventFromEvent(events, EventEventType.TrialDay, 6),
              healthcheckEvent: this.getProgressBarEventFromEvent(events, EventEventType.HealthCheckPending, 7),
              employmentEvent: this.getProgressBarEventFromEvent(events, EventEventType.Employment, 8),
              officeInterview: interviewEvent,
              presentingToClientEvent,
              // clientInterviewEvent: this.clientInterview,
              // employmentEvent,
              lastSegmentIdx: 1,
              order: it.id,
              originalOrderId,
              offerPropositionId,
              orderType: it.order.orderType,
              healthCheckPendingEvent
            };


            map['lastSegmentIdx'] = Math.max(
              map.officeInterviewInvitationEvent.status ? 2 : 1,
              map.officeInterviewEvent.status ? 3 : 1,
              map.presentToClientEvent.status ? 4 : 1,
              map.clientInterviewEvent.status ? 5 : 1,
              map.healthcheckEvent.status ? 6 : 1,
              map.employmentEvent.status ? 7 : 1
            );
            orders.push(map);
          }
        );
        this.originalDS = orders;

        /** preserve search */
        const value = this.filterValue.toLowerCase();
        this.dataSource.data.next(value ? orders.filter(
          (e) =>
            e.title.toLowerCase().includes(value) ||
            e.companyName.toLowerCase().includes(value)
        ) : orders);
        const sortState: Sort = {active: 'createdAt', direction: 'asc'};
        this.sort.sortChange.emit(sortState);
      });
  }



  setAnswer(
    row: OffersTableItem,
    resp: OfferPropositionInterested,
    comment?: string,
    signed?: boolean
  ) {
    const input = {
      id: row.responseId ? btoa('OfferPropositionType:' + row.responseId) : undefined,
      comment: comment ? comment : row.comment,
      order: row.id,
      personId: this.personId,
      interested: resp || undefined,
      interestedOnCall:
        (row.interestedOnCall && row.interestedOnCall.toLowerCase()) ||
        undefined,
      signed: signed === undefined ? row.signed : signed,
    };

    this.updateResponse(row, input);
  }

  private updateResponse(row: OffersTableItem, input) {
    input.id? this.updateOfferProposition(row, input) : this.createOfferProposition(row, input);
  }

  private createOfferProposition(row: OffersTableItem, input) {
    this.offerPropositionCreateGQL.mutate({ input }).subscribe((change) => {
      const prop = change.data.offerPropositionCreate?.offerProposition;
      this.parseOfferPropositionResponse(row, prop);
    });
  }

  private updateOfferProposition(row: OffersTableItem, input) {
    this.offerPropositionUpdateGQL.mutate({ input }).subscribe((change) => {
      const prop = change.data.offerPropositionUpdate?.offerProposition;
      this.parseOfferPropositionResponse(row, prop);
    });
  }

  parseOfferPropositionResponse(row: OffersTableItem, prop) {
    row.comment = prop.comment;
    row.interested = prop.interested;
    row.interestedOnCall = prop.interestedOnCall;
    row.responseId = this.getId(prop.id);
    row.signed = prop.signed;
    row.candidate = prop.candidate?.id;
    row.longListed = prop.longListed;
  }

  private getId(a: string) {
    return atob(a).split(':')[1];
  }

  setEmployed(row: any, signed: boolean) {
    this.setAnswer(row, row.interested, row.comment, !signed);
  }

  setCallAnswer(
    row: OffersTableItem,
    resp: OfferPropositionInterestedOnCall,
    comment?: string,
    signed?: boolean
  ) {
    const input = {
      id: row.responseId ? btoa('OfferPropositionType:' + row.responseId) : undefined,
      comment: comment ? comment : row.comment,
      order: row.id,
      personId: this.personId,
      interestedOnCall: resp,
      interested: (row.interested && row.interested.toLowerCase()) || undefined,
      signed: signed === undefined ? row.signed : signed,
    };

    this.updateResponse(row, input);
  }

  getTooltipText(row: OffersTableItem) {
    return CALL_OFFER_RESPONSES[row.interestedOnCall] || WAS_NOT_OFFERED;
  }
  isCancelled(events: any, interviewType: string) {
    let data = events?.find(
      (node: any) => node.node.eventType === interviewType
    );
    if (data) {
      if ([EventResolutionStatus.Cancelled, EventResolutionStatus.Failed].indexOf(data.node.resolutionStatus) > -1){
        return '#FF5722';
      } else {
        return '#7cb342';
      }
    }
    return '';
  }

  scheduleEvent(row: OffersTableItem, $event, interviewType: string) {
    // const dialogRef = this.dialog.open(ScheduleEventDialogComponent, {
    //   width: '600px',
    //   data: {
    //     comment: row.comment,
    //     relatedOffer: btoa('OfferPropositionType:' + row.responseId),
    //     candidate: this.personId,
    //     candidateId: row.candidate,
    //     interviewType: interviewType,
    //     candidateEvent: row.candidateEvent,
    //     companyName: row.companyName,
    //     companyStatus: row.companyStatus,
    //   },
    // });

    // dialogRef.afterClosed().subscribe((newDate) => {
    // });
  }

  filterTable() {
    const value = this.filterValue.toLowerCase();
    this.dataSource.data.next(
      this.originalDS.filter(
        (e) =>
          e.title.toLowerCase().includes(value) ||
          e.companyName.toLowerCase().includes(value)
      )
    );
  }

  private openCancellationReasonDialog(offerPropositionId?: number) {
    // return this.dialog.open(CancellationReasonDialogComponent, {
    //   data: { offerPropositionId, country: this.$country },
    //   width: '50%'
    // });
  }

  dateFormatFirstPart(date: string) {
    const localeData = moment.localeData();
    let format = localeData.longDateFormat('L');
    format = format.replace(/.YYYY|YYYY.|-YYYY|YYYY-|\/YYYY|YYYY\//, '');

    var momentWithoutYear = moment(date);
    const formatterFirstPart = momentWithoutYear.format(format);

    return formatterFirstPart.substring(0, formatterFirstPart.length);
  }

  dateFormatSecondPart(date: string) {
    return new Date(date).getFullYear();
  }

  openCandidateEvaluationDialog(id: any) {
    // this.dialog.open(CandidateEvaluationsDialogComponent, {
    //   candidate: {},
    //
    // });
  }

  getCommentsTooltip(row: any): string {
    return row.offerpropositioncommentSet.edges.reduce((acc, comment, idx) => acc + `\n\n#${idx + 1}  ${comment.node.text};`, '');
  }
}
