import { Injectable } from '@angular/core';
import {
  EventEventType,
  EventResolutionStatus,
  InterviewWidgetChangeEventStatusMutationGQL,
  InterviewWidgetCreateEventGQL,
  InterviewWidgetEventUpdateGQL,
  OfferPropositionCreateGQL,
  OfferPropositionInterested,
  OfferPropositionUpdateGQL,
  UpdateEventProblematicInterviewGQL,
  UpdateLivasCandidateStatusByOfferPropositionGQL,
  UpdateLivasOfferPropositionSignedGQL
} from '../../../generated/graphql';
import {OffersTableItem} from './offers-table-datasource';
import {TranslateService} from '@ngx-translate/core';
import {ProgressBarData, ProgressStages} from './progress-component/types';
import moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class ProgressActionsServiseService {
  loading = false;

  constructor(
    private createCandidateEventGQL: InterviewWidgetCreateEventGQL,
    private updateCandidateEventGQL: InterviewWidgetEventUpdateGQL,
    private changeCandidateEventStatusGQL: InterviewWidgetChangeEventStatusMutationGQL,
    private offerPropositionCreateGQL: OfferPropositionCreateGQL,
    private offerPropositionUpdateGQL: OfferPropositionUpdateGQL,
    private offerPropositionUpdateSignedGQL: UpdateLivasOfferPropositionSignedGQL,
    private offerPropositionUpdateCandidateStatusGQL: UpdateLivasCandidateStatusByOfferPropositionGQL,
    private updateEventProblematicInterviewGQL: UpdateEventProblematicInterviewGQL,
    private translate: TranslateService
  ) { }




  createUpdateEvent(eventId: string,
                    offerPropositionId: string,
                    eventType: EventEventType,
                    startDatetime?: string,
                    livasStatusId?: number,
                    nextContactDate?: string,
                    updateLivasOfficeInterviewDate?: boolean,
                    updateLivasClientInterviewDate?: boolean,
                    updateLivasEmploymentDate?: boolean) {
    const params = {
      eventId,
      offerPropositions: btoa('OfferPropositionType:' + offerPropositionId),
      eventType,
      startDatetime,
      livasStatusId,
      livasContactDate: nextContactDate,
      updateLivasOfficeInterviewDate,
      updateLivasClientInterviewDate,
      updateLivasEmploymentDate
    };

    let mutation;

    if (eventId) {
      mutation = this.updateCandidateEventGQL.mutate({...params}, {refetchQueries: ['InterviewWidgetOffers', 'OfferPospositionOffers']});
    } else {
      mutation = this.createCandidateEventGQL.mutate({...params}, {refetchQueries: ['InterviewWidgetOffers', 'OfferPospositionOffers']});
    }
    return mutation;
  }

  problematicInterviewUpdate(
    eventId: string,
    description: string,
    isProblematicInterview: boolean
  ) {
    const params = {
      id: eventId,
      isProblematicInterview,
      description
    };

    return this.updateEventProblematicInterviewGQL.mutate(
      { ...params }, { refetchQueries: ['InterviewWidgetOffers', 'OfferPospositionOffers'] }
    );
  }

  changeEventStatus(eventId: string, resolutionStatus: EventResolutionStatus) {
    const params = {
      eventId,
      resolutionStatus
    };

    let mutation;

    mutation = this.changeCandidateEventStatusGQL.mutate(params, {refetchQueries: ['InterviewWidgetOffers', 'OfferPospositionOffers']});
    return mutation;
  }

  updateCreateOfferProposition(responseId: string, orderId: string, personId: number, interestedInOffice: OfferPropositionInterested) {
    let re;
    if (responseId) {
      // update
      const input = {
        id: responseId,
        interested: interestedInOffice
      };
      re = this.updateOfferProposition(input);
    } else {
      const input = {
        order: orderId,
        personId,
        interested: interestedInOffice
      };
      re = this.createOfferProposition(input);
    }
    return re;
  }

  private createOfferProposition(input) {
    return this.offerPropositionCreateGQL.mutate({ input }, {refetchQueries: ['InterviewWidgetOffers', 'OfferPospositionOffers']});
  }

  private updateOfferProposition(input) {
    return this.offerPropositionUpdateGQL.mutate({ input }, {refetchQueries: ['InterviewWidgetOffers', 'OfferPospositionOffers']});
  }

  updateLivasCandidateStatusByOfferProposition(livasStatusId: number, offerPropositionId: string) {
    return this.offerPropositionUpdateCandidateStatusGQL.mutate(
      {
        livasStatusId,
        offerPropositionId
      },
      { refetchQueries: ['InterviewWidgetOffers', 'OfferPospositionOffers'] }
    );
  }

  getStageLabel(stage: ProgressStages, events: ProgressBarData) {
    const label = {
      [ProgressStages.longListEvent]: 'PROGRESS.LONG_LIST_STATUS',
      [ProgressStages.introductionCallEvent]: 'PROGRESS.INTRO_CALL_INTERVIEW',
      [ProgressStages.officeInterviewInvitationEvent]: 'PROGRESS.INVITED_TO_INTERVIEW',
      [ProgressStages.officeInterviewEvent]: 'PROGRESS.INTERVIEW_REPLY',
      [ProgressStages.presentToClientEvent]: 'PROGRESS.PRESENTING_TO_CLIENT',
      [ProgressStages.clientInterviewEvent]: 'PROGRESS.CLIENT_INTERVIEW',
      [ProgressStages.trialDay]: 'PROGRESS.TRIAL_DAY',
      [ProgressStages.healthcheckEvent]: 'PROGRESS.HEALTHCHECK_EVENT',
      [ProgressStages.employmentEvent]: 'PROGRESS.EMPLOYMENT_EVENT',
    }[stage];

    const event = events ? events[stage] : undefined;
    const statusLabel = event?.status ? this.translate.instant(('PROGRESS.' + event.status)) : '';
    let dateTime = '';

    if (events && events[stage]?.status && events[stage]?.startDatetime) {
      dateTime = moment(events[stage]?.startDatetime).format('YYYY-MM-DD HH:mm');
    }

    return `${this.translate.instant(label)}. ${statusLabel} ${dateTime}`;
  }


  getStageInfo(stage: ProgressStages) {
    return {
      [ProgressStages.officeInterviewInvitationEvent]: 'PROGRESS.INVITED_TO_INTERVIEW_INFO',
      [ProgressStages.officeInterviewEvent]: 'PROGRESS.INTERVIEW_REPLY_INFO',
      [ProgressStages.presentToClientEvent]: 'PROGRESS.PRESENTING_TO_CLIENT_INFO',
      [ProgressStages.clientInterviewEvent]: 'PROGRESS.CLIENT_INTERVIEW_INFO',
      [ProgressStages.trialDay]: 'PROGRESS.TRIAL_DAY_INFO',
      [ProgressStages.healthcheckEvent]: 'PROGRESS.HEALTHCHECK_EVENT_INFO',
      [ProgressStages.employmentEvent]: 'PROGRESS.EMPLOYMENT_EVENT_INFO',
    }[stage];
  }

  setOfferPropositionSigned(responseId: string) {
    const offerPropositionId = btoa(`OfferPropositionType:${responseId}`);
    return this.offerPropositionUpdateSignedGQL.mutate({offerPropositionId});
  }
}
