import {Component, Input, OnInit} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import {Tag, TagAssignment} from '../../../../specification/tag-assignment-table/tag';
import {InputModes, ScheduleChangeEvent} from '../../../../specification/schedule-setup/schedule-setup.component';
import {RiskFactor, Specification} from '../../../../graphql/graphql';
import {TagAssignmentTableItem} from '../../../../specification/tag-assignment-table/tag-assignment-table-datasource';
import {OrderTypeEnum, TagGroupKey} from '../../../../shared/types/order-types';
import {IntensityOptions} from '../../../../specification/specification-edit/specification-edit.component';
import {OrderCreateService} from '../../../../order/order-create/order-create.service';
import { TranslateService } from '@ngx-translate/core';
import { BaseOrderFieldsFragment, QualificationEnum } from 'src/generated/graphql';
@Component({
  selector: 'app-workplace-description',
  templateUrl: './workplace-description.component.html',
  styleUrls: ['./workplace-description.component.scss']
})
export class WorkplaceDescriptionComponent implements OnInit {
  private spec: Specification;
  scheduleInputMode: InputModes;
  tagGroupMap = TagGroupKey;
  tagAssignments: TagAssignment[] = [];
  riskFactors: RiskFactor[] = [];
  markDefault = false;
  $order: BaseOrderFieldsFragment;
  @Input() set order(val: BaseOrderFieldsFragment) {
    this.$order = val;
    this.markDefault = !val;
    if (val?.specification) {
      this.tagAssignments =  val.specification.tagassignmentSet;
      this.riskFactors = val.specification.riskFactors;
    }
  };
  get order() {
    return this.$order;
  }

  intensityOptions = [
      IntensityOptions.stationary,
      IntensityOptions.stationaryFast,
      IntensityOptions.sedentary,
      IntensityOptions.sedentaryFast,
      IntensityOptions.sedentaryAndStationary,
  ];
  orderTypes = OrderTypeEnum;
  @Input() transportInfoTags: Tag[];

  private $form: any;
  @Input() set form(value: FormGroup) {
    this.$form = value;
  }
  get form() { return this.$form; }
  @Input() orderForm: FormGroup;
  private $orderType: OrderTypeEnum;
  @Input() set orderType(value: OrderTypeEnum) {
    this.$orderType = value;
  }
  get orderType() { return this.$orderType; }

  @Input() onTagsSelection: ($event: TagAssignmentTableItem[], groupKey: string) => {};
  qualificationEnum = QualificationEnum;

  get recruitmentProgressReportFrequencyCtrl() {
    return this.orderForm && this.orderForm.get('recruitmentProgressReportFrequency') as FormControl;
  }

  constructor(private createOrderService: OrderCreateService, private translateService: TranslateService) { }

  ngOnInit(): void {
  }

  onRiskFactorsUpdate($event: RiskFactor[]) {
    const factors = [];
    $event.forEach((rf) => {
      factors.push(rf.id);
    });
    this.form.get('riskFactors').setValue(factors);
  }
}
