import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {ProgressActionDialogComponent} from '../progress-action-dialog/progress-action-dialog.component';
import {ProgressActionsServiseService} from '../progress-actions-servise.service';
import {ProgressBarData, ProgressStages} from './types';

@Component({
  selector: 'app-progress-component',
  templateUrl: './progress-component.component.html',
  styleUrls: ['./progress-component.component.scss']
})
export class ProgressComponentComponent implements OnInit {
  public $events: ProgressBarData;
  stages = [
    'longListEvent',
    'introductionCallEvent',
    'officeInterviewInvitationEvent',
    'officeInterviewEvent',
    'presentToClientEvent',
    'clientInterviewEvent',
    'trialDay',
    'healthcheckEvent',
    'employmentEvent'
  ];


  private includeTrialDayInProcess = false;

  @Input() countryIso;
  @Input() set events(value: ProgressBarData) {
    this.$events = value;
  }
  @Input() personId;
  @Input() isSegmentClickable = true;

  @ViewChild('officeArrived') officeArrivedInteviuMenu;
  @ViewChild('officeInterview') officeInteviewMenu;

  constructor(
    private dialog: MatDialog,
    public progressService: ProgressActionsServiseService
  ) { }

  ngOnInit(): void {
    if (this.countryIso !== 'ee') {
      this.stages = this.stages.filter(stage => stage !== 'trialDay');
    } else {
      this.includeTrialDayInProcess = true;
    }
  }

  getMenuForStage(stage) {

    return {
      officeInterviewInvitationEvent: this.officeArrivedInteviuMenu,
      officeInterviewEvent: this.officeInteviewMenu
    }[stage];
  }

  onProgressClickEvent($events: ProgressBarData, stage: string) {
    if (this.isInterviewStage(stage)) {
      this.dialog.open(ProgressActionDialogComponent, {
        data: { events: this.$events, stage, personId: this.personId, countryIsoId: this.countryIso }
        //  todo add duration setting to remove animation

      });

    }
  }

  isInterviewStage(stage) {
    if (stage === ProgressStages.longListEvent || stage === ProgressStages.introductionCallEvent ) {
      return false;
    } else {
      return  true;
    }
  }

  isWarning(stage: string): boolean {
    if (stage === ProgressStages.officeInterviewInvitationEvent && this.$events['officeInterview']?.isProblematicInterview) {
      return true;
    }

    return false;
  }
}
