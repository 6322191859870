import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';

import { StandupDashboardServiceService } from '../standup-dashboard-service.service';
import { DashboardType, TaskDetailViewFieldsFragment, UserProfileMType } from '../../../../generated/graphql';
import { NewTaskDialogComponent } from '../../../planner/new-task-dialog/new-task-dialog.component';
import { OverdueTasksTableComponent } from '../overdue-tasks-table/overdue-tasks-table.component';
import { TaskServiceService } from '../../../planner/task-service.service';
import { environment } from '../../../../environments/environment';
import * as LogRocket from 'logrocket';
import { UserProfileGQL } from '../../../auth/graphql';
import { MeetingClientComponent } from '../meeting-client/meeting-client.component';

@Component({
  selector: 'app-standup-dashboard',
  templateUrl: './standup-dashboard.component.html',
  styleUrls: ['./standup-dashboard.component.scss']
})
export class StandupDashboardComponent implements OnInit {
  dashboards: any;
  selectedDashboard: DashboardType;
  dashboardMembers: UserProfileMType[];

  @ViewChild('taskOverdueTable') taskOverdueTable: OverdueTasksTableComponent;
  @ViewChild('meetingOverdueTable') meetingOverdueTable: MeetingClientComponent;
  constructor(
    public dashboardService: StandupDashboardServiceService,
    private translate: TranslateService,
    private snackBar: MatSnackBar,
    private tasksService: TaskServiceService,
    private route: ActivatedRoute,
    private taskService: TaskServiceService,
    private dialog: MatDialog,
    private location: Location,
    private router: Router,
    private userprofileGQL: UserProfileGQL,
  ) { }

  ngOnInit(): void {
    this.dashboards = this.dashboardService.getDashboards();
    this.dashboards.subscribe(data => {
      const storedDashboard = this.dashboardService.getStoredSelectedDashboard();
      if (storedDashboard) {
        this.selectedDashboard = data.find(item => item.id === storedDashboard) || data[0];
      } else {
        this.selectedDashboard = data[0];
      }
      this.updateUrlParameter(this.selectedDashboard.id);
      this.route.paramMap.subscribe(params => {
        const taskId = params.get('id');
        if (taskId) {
          this.taskService.getTask(taskId).subscribe(
            (task) => {
              this.openTaskDialog(task.data.task);
            }
          );
        }
      });
      this.dashboardMembers = this.getDashboardMembers(this.selectedDashboard);
    });

    if (environment.production && !window['logRocketInitialized']) {
      LogRocket.init('biuro-baltic/boris-uhpvp');
      this.userprofileGQL.fetch().subscribe(resp => {
        if (LogRocket && LogRocket.identify) {
          LogRocket.identify(resp.data.userprofile.user.email);
          window['logRocketInitialized'] = true;
        }
      });
    }
  }

  updateUrlParameter(dashboardId: string) {
    this.router.navigate([], {
      relativeTo: this.router.routerState.root,
      queryParams: { selectedDashboard: dashboardId },
      queryParamsHandling: 'merge'
    });
  }

  openNewTaskDialog() {
    const config: MatDialogConfig<any> = {
      data: {
        dashboardId: this.selectedDashboard.id
      },
      panelClass: 'rounded-dialog-16',
      width: '600px',
      height: '640px',
      autoFocus: false
    };

    this.dialog.open(NewTaskDialogComponent, config).afterClosed().subscribe(value => {
      if (value?.id) {
        this.snackBar.open(
          this.translate.instant('CREATED_SUCCESSFULLY'), null, { duration: 5000, horizontalPosition: 'left' }
        );
        this.taskOverdueTable?.loadOverdueTasks(this.taskOverdueTable?.displayOverdueOnly);
        this.meetingOverdueTable?.loadMeetingTasks(this.meetingOverdueTable?.displayAssignedOnly);
      }
    });
  }

  openTaskDialog(task: TaskDetailViewFieldsFragment) {
    const config: MatDialogConfig<any> = {
      data: { task },
      panelClass: 'rounded-dialog-16',
      width: '600px',
      height: '640px',
      autoFocus: false
    };
    if (task) {
      config.data = { task };
      this.location.replaceState(`/standups/${encodeURIComponent(task.id)}`);
    }
    this.dialog.open(NewTaskDialogComponent, config).afterClosed().subscribe(value => {
      if (value?.delete) {
        this.snackBar.open(
          this.translate.instant('DELETED_SUCCESSFULLY'), null, { duration: 5000, horizontalPosition: 'left' }
        );
      } else if (value?.id) {
        task.date = value.date;
        task.status = value.status;
        task.comment = value.comment;
        task.dashboard = value.dashboard;
        this.snackBar.open(
          this.translate.instant('UPDATED_SUCCESSFULLY'), null, { duration: 5000, horizontalPosition: 'left' }
        );
      }
      this.location.replaceState('/standups');
    });
  }

  getDashboardMembers(dashboard: DashboardType): UserProfileMType[] {
    return dashboard ? dashboard.metricsUserprofiles.edges.map(edge => edge.node) : [];
  }

  onDashboardChange(event: { value: DashboardType }) {
    this.dashboardService.storeSelectedDashboard(event.value.id);
    this.updateUrlParameter(this.selectedDashboard.id);
    this.dashboardMembers = this.getDashboardMembers(event.value);
  }
}
