<div fxLayout="row">
  <ng-container *ngFor="let stage of stages; let first=first; let last=last">
    <app-progress-bar-segment (click)='isSegmentClickable ? onProgressClickEvent($events, stage) : false'
                              [icon]='$events[stage]?.icon'
                              [first]='first'
                              [last]='last'
                              [status]='$events[stage].status'
                              [clickable]='isSegmentClickable'
                              [matTooltip]='progressService.getStageLabel(stage, $events)'
                              [showWarning]="isWarning(stage)"
    ></app-progress-bar-segment>
    <mat-menu #placeholder="matMenu" [class]="'placeholderMenu'" ></mat-menu>
  </ng-container>
</div>
